import { Injectable } from '@angular/core';
import {
  CustomerRequestsGQL,
  CustomersGQL,
  UpdateCustomerRequestStatusInput,
  UpdateCustomerRequestStatusGQL,
  CustomerFilterInput, UpdateCustomerPricesGQL, UpdateCustomerPricesInput, CoventoCustomerFilterInput
} from '../../generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(
    private customersGQL: CustomersGQL,
    private requestsGQL: CustomerRequestsGQL,
    private updateStatusGQL: UpdateCustomerRequestStatusGQL,
    private updateCustomerPricesGQL: UpdateCustomerPricesGQL
  ) { }

  getCustomers(where?: CoventoCustomerFilterInput) {
    return this.customersGQL.watch({
      where
    }).valueChanges;
  }

  updateCustomerPrices(input: UpdateCustomerPricesInput) {
    return this.updateCustomerPricesGQL.mutate({input});
  }

  getRequests() {
    return this.requestsGQL.watch().valueChanges;
  }

  updateStatus(input: UpdateCustomerRequestStatusInput) {
    return this.updateStatusGQL.mutate({input},{
      refetchQueries: [
        {query: this.requestsGQL.document}
      ]
    })
  }
}
